import React from "react"
import Loadable from 'react-loadable'

const LoadAuthorsGraph = Loadable({
	loader: () => import(`./component`),
	loading: ({ timedOut }) =>
		timedOut ? (
			<blockquote>Error: Loading AuthorsGraph timed out.</blockquote>
		) : (
			<div>Loading...</div>
		),
	timeout: 30000,
})

export default function LazyAuthorsGraph(props) {
  return(
    <LoadAuthorsGraph {...props} />
	)
}