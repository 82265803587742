import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"
import AuthorsGraph from "../../components/AuthorsGraph"

export default function PRNPage({ pageContext: { language }, data: { text } }) {

	return(
		<Layout
      lang={language}
			availableLanguages={["th", "en"]}
      title={"PIER Research Network"}
      pathname={"/pier-research-network/"}
			customTitle={true}
    >
      <AuthorsGraph lang={language} />
      <MDXRenderer>{text.nodes[0].body}</MDXRenderer>
		</Layout>
	)

}

export const pageQuery = graphql`
	query PRNQuery($language: String!) {
		text: allMdx(filter: {
      fields: {
        collection: {eq: "snippets"},
        lang: {eq: $language}
        originalPath: {eq: "/pier-research-network/"}
      }
    }) {
      nodes {
        body
      }
    }
	}
`